/* Setup Email */
import * as React from "react";
import { Dialog, Grid, TextField } from "@mui/material";

import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import OTPInput from "../../components/ui/OTPInput";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";

export default function StepEmail({
  onSave,
  processStepUrl,
  from,
  directEmail,
  isMobileApp,
}) {
  const { getLabel } = useLabels();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [askOTP, setAskOTP] = React.useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email) {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      setAskOTP(true);
    }
  };

  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + processStepUrl,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave(email, values);
      },
      onError: (values) => {
        if (values?.response?.data?.status) {
          navigate("/underReview");
        }
      },
    }
  );

  React.useEffect(() => {
    if (
      directEmail !== undefined &&
      directEmail !== null &&
      directEmail !== ""
    ) {
      setEmail(directEmail);
    }
  }, [directEmail]);

  const handleOnSuccessOTP = (code) => {
    setAskOTP(false);
    processStep.mutate({ email, code });
  };

  const FormOTP = () => (
    <Dialog
      open={askOTP}
      fullScreen
      onClose={(event, reason) => {
        reason !== "backdropClick" && setAskOTP(false);
      }}
      disableEscapeKeyDown={true}
    >
      <OTPInput
        recipient={email}
        from={from}
        onClose={() => setAskOTP(false)}
        onSuccess={(code) => handleOnSuccessOTP(code)}
        openOTP={true}
        isMobileApp={isMobileApp}
      />
    </Dialog>
  );

  return (
    <>
      <FormOTP />
      <Grid container>
        <Grid item xs={12} p={0.5}>
          <TextField
            size="small"
            label="Email"
            id="email"
            required
            type={"text"}
            value={email}
            disabled={
              directEmail === undefined ||
              directEmail === null ||
              directEmail === ""
                ? false
                : true
            }
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
            }}
            error={!!emailError}
            helperText={emailError}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSave}
            loading={processStep.isLoading}
          >
            {getLabel("onboarding.1.btnContinue", "Continue")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
